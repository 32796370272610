<template>
  <Container size="small" v-if="pageBreadcrumbs.length > 1">
    <Breadcrumbs :items="pageBreadcrumbs" />
  </Container>
  <component
    :is="componentName(element, components)"
    v-for="element in page.elements"
    :key="element.id"
    :element="element"
    :class="NARROW_ELEMENTS.includes(element.name) && 'narrow'"
    data-template="content-page"
  />
</template>

<script setup lang="ts">
  import { useNotificationStore } from "~/pinia/notifications"
  const { componentName } = useAlchemy()
  const notificationStore = useNotificationStore()

  const NARROW_ELEMENTS = [
    "text_block",
    "fragrance_teaser",
    "headline",
    "glossary",
    "documents_list",
    "table_of_contents",
    "faq",
  ]

  const props = defineProps<{
    page: AlchemyPage
  }>()

  const pageBreadcrumbs = usePageBreadcrumbs(props.page)
  useHead(usePageInfo(props.page))

  onMounted(() => {
    notificationStore.announce(props.page.title)
  })

  const components = {
    usage_table: defineAsyncComponent(
      () => import("~/alchemy/elements/usage_table.vue"),
    ),
    room_spray_dilution_calculator: defineAsyncComponent(
      () => import("~/alchemy/elements/room_spray_dilution_calculator.vue"),
    ),
    candle_recipe: defineAsyncComponent(
      () => import("~/alchemy/elements/candle_recipe.vue"),
    ),
    kit_items: defineAsyncComponent(
      () => import("~/alchemy/elements/alchemy_kit_items.vue"),
    ),
    article_teaser_new_storefront: defineAsyncComponent(
      () => import("~/alchemy/elements/article_teaser_new_storefront.vue"),
    ),
    block_quote: defineAsyncComponent(
      () => import("~/alchemy/elements/block_quote.vue"),
    ),
    cards: defineAsyncComponent(() => import("~/alchemy/elements/cards.vue")),
    curalate_embed: defineAsyncComponent(
      () => import("~/alchemy/elements/curalate_embed.vue"),
    ),
    disqus_embed: defineAsyncComponent(
      () => import("~/alchemy/elements/disqus_embed.vue"),
    ),
    divider: defineAsyncComponent(
      () => import("~/components/Divider/index.vue"),
    ),
    documents_list: defineAsyncComponent(
      () => import("~/alchemy/elements/documents_list.vue"),
    ),
    email_signup: defineAsyncComponent(
      () => import("~/alchemy/elements/email_signup.vue"),
    ),
    fragrance_finder: defineAsyncComponent(
      () => import("~/alchemy/elements/fragrance_finder.vue"),
    ),
    fragrance_teaser: defineAsyncComponent(
      () => import("~/alchemy/elements/fragrance_teaser.vue"),
    ),
    gallery: defineAsyncComponent(
      () => import("~/alchemy/elements/gallery.vue"),
    ),
    glossary: defineAsyncComponent(
      () => import("~/alchemy/elements/glossary.vue"),
    ),
    headline: defineAsyncComponent(
      () => import("~/alchemy/elements/headline.vue"),
    ),
    helpbox: defineAsyncComponent(
      () => import("~/alchemy/elements/helpbox.vue"),
    ),
    holiday_schedule: defineAsyncComponent(
      () => import("~/alchemy/elements/holiday_schedule.vue"),
    ),
    ifra_table: defineAsyncComponent(
      () => import("~/alchemy/elements/ifra_table.vue"),
    ),
    image_full_width: defineAsyncComponent(
      () => import("~/alchemy/elements/image_full_width.vue"),
    ),
    link_list: defineAsyncComponent(
      () => import("~/alchemy/elements/link_list.vue"),
    ),
    main_header: defineAsyncComponent(
      () => import("~/alchemy/elements/main_header.vue"),
    ),
    masthead_video: defineAsyncComponent(
      () => import("~/alchemy/elements/masthead_video.vue"),
    ),
    masthead: defineAsyncComponent(
      () => import("~/alchemy/elements/masthead.vue"),
    ),
    newsletter_bar: defineAsyncComponent(
      () => import("~/alchemy/elements/email_signup.vue"),
    ),
    page_hero_alternate: defineAsyncComponent(
      () => import("~/alchemy/elements/page_hero_alternate.vue"),
    ),
    panel_row: defineAsyncComponent(
      () => import("~/alchemy/elements/panel_row.vue"),
    ),
    pinterest_gallery: defineAsyncComponent(
      () => import("~/alchemy/elements/pinterest_gallery.vue"),
    ),
    pro_tip: defineAsyncComponent(
      () => import("~/alchemy/elements/pro_tip.vue"),
    ),
    product_list: defineAsyncComponent(
      () => import("~/alchemy/elements/product_list.vue"),
    ),
    product_slider: defineAsyncComponent(
      () => import("~/alchemy/elements/product_slider.vue"),
    ),
    product: defineAsyncComponent(
      () => import("~/alchemy/elements/product.vue"),
    ),
    project_items: defineAsyncComponent(
      () => import("~/alchemy/elements/project_items.vue"),
    ),
    section_with_background: defineAsyncComponent(
      () => import("~/alchemy/elements/section_with_background.vue"),
    ),
    story_banner: defineAsyncComponent(
      () => import("~/alchemy/elements/story_banner.vue"),
    ),
    table_of_contents: defineAsyncComponent(
      () => import("~/components/TableOfContents/index.vue"),
    ),
    taxon_slider: defineAsyncComponent(
      () => import("~/alchemy/elements/taxon_slider.vue"),
    ),
    testimonial: defineAsyncComponent(
      () => import("~/alchemy/elements/testimonial.vue"),
    ),
    testimonials: defineAsyncComponent(
      () => import("~/alchemy/elements/testimonials/index.vue"),
    ),
    text_block: defineAsyncComponent(
      () => import("~/alchemy/elements/text_block.vue"),
    ),
    text_image_left: defineAsyncComponent(
      () => import("~/alchemy/elements/text_image_left.vue"),
    ),
    text_image_right: defineAsyncComponent(
      () => import("~/alchemy/elements/text_image_right.vue"),
    ),
    grid_columns: defineAsyncComponent(
      () => import("~/alchemy/elements/grid_columns.vue"),
    ),
    wistia_embed: defineAsyncComponent(
      () => import("~/alchemy/elements/wistia_embed.vue"),
    ),
    faq: defineAsyncComponent(() => import("~/alchemy/elements/faq.vue")),
    spree_promotion: defineAsyncComponent(
      () => import("~/alchemy/elements/spree_promotion.vue"),
    ),
  }
</script>

<style lang="scss" scoped>
  .narrow {
    max-width: $content-width-small;
    margin: 0 auto;
  }
</style>
